<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Help Sections')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="toggleFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          class="q-ml-sm"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="toggleFilters"
      />

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="helpSections"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="helpSectionsLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="name"
                :props="props"
              >
                {{ props.row.name }}
              </q-td>

              <q-td
                key="route"
                :props="props"
              >
                {{ props.row.route }}
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-toggle
                  color="light-blue-9"
                  :model-value="props.row.state === 'active'"
                  @update:model-value="handleStateChange(props.row)"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin.vue'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'HelpSections',
  components: {
    Search,
    FilterCollapse
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Route'),
          name: 'route',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        }
      ],
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'state',
        'id',
        'name',
        'route',
        'created.from',
        'created.to'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'helpSections',
      'totalHelpSectionsNumber',
      'helpSectionsPage',
      'helpSectionsLoading',
      'appOptions'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ' + this.totalHelpSectionsNumber)
        : this.$t('Filter')
    }
  },
  mounted () {
    this.pagination = {
      ...this.pagination,
      page: this.helpSectionsPage,
      rowsNumber: this.totalHelpSectionsNumber
    }

    if (this.helpSections.length > 0) {
      return
    }

    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadHelpSections'
    ]),
    ...mapMutations([
      'setHelpSection',
      'upsertHelpSection'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return this.$service.helpSection.save({ state }, item.id)
        .then(item => {
          this.upsertHelpSection(item)
        })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    toggleFilters () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({})
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadHelpSections(this.serverParams)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          return { items, totalPages, page, totalItems }
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    create () {
      this.$router.push('/settings/help-sections/entity')
    },
    onRowDblClick (row) {
      this.setHelpSection(row)
      this.$router.push('/settings/help-sections/entity/' + row.id)
    }
  }
}
</script>
